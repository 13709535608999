<template>
  <div class="full-height">
    <div class="pad-top-twenty margin-bottom-thirty">
      <div :class="isCarded ? 'scrollable' : ''">
        <div>
          <ion-row v-if="!isCarded">
            <ion-col class="margin-bottom-ten">
              <h3>Event Notifications</h3>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
    <AgGridVue style="width: 100%; height: 100%" class="prime-grid ag-theme-alpine" rowHeight="62"
      :columnDefs="columnDefs" :defaultColDef="defaultColDef" :rowData="rowData" :sideBar="sideBar"
      @grid-ready="onGridReady" @rowClicked="onRowClicked" :modules="modules"></AgGridVue>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import store from "@/store";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { AgGridVue } from "@ag-grid-community/vue";
import { ClipboardModule } from "@ag-grid-enterprise/clipboard";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { addIcons } from "ionicons";

import ModalEventNotification from "@/components/Settings/Patient/ModalEventNotification";
import { more } from "ionicons/icons";
import moment from "moment";
import { engageFhirApi } from "../../../services/EngageFhirApi";
import { getPrimeEncounter } from "./EventNotification";


addIcons({
  "md-more": more.md
});

export default {
  name: "EventsNotifications",
  components: {
    AgGridVue
  },
  props: {
    view: String,
    currentPatient: Object,
    isCarded: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {
          headerName: "Date/Time", field: "eventDate", valueFormatter: this.timeFormatter, sortable: true, editable: true, filter: "agDateColumnFilter",
          filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              let dateAsString = moment(cellValue).utc().format("MM/DD/YYYY");
              let dateParts = dateAsString.split("/");
              let cellDate = new Date(Number(dateParts[2]), Number(dateParts[0]) - 1, Number(dateParts[1]));

              if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                return 0
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            }
          }
        },
        { headerName: "Admission Type", field: "admissionType", filter: true },
        { headerName: "Event", field: "eventTitle", filter: true },
        { headerName: "Patient ID", field: "patientId", filter: 'agTextColumnFilter', hide: true },
        { headerName: "Visit ID", field: "visitNumber", filter: 'agTextColumnFilter', hide: true },
        { headerName: "Location", field: "location", filter: true, valueGetter: this.fillLocationRoomPOC },
        { headerName: "Patient Class", field: "patientClass", filter: true },
        { headerName: "Attending Dr.", field: "attendingDoctor", filter: true },
        { headerName: "Admitting Dr.", field: "admittingDoctor", filter: true, hide: true },
        { headerName: "Consulting Dr.", field: "consultingDoctor", filter: true, hide: true },
        { headerName: "Dx Codes", field: "diagnosisCodes", valueGetter: this.jsonFormatter, filter: 'agTextColumnFilter', filterParams: { valueFormatter: this.jsonFormatter }, cellStyle: { 'white-space': 'pre-wrap' } },
        { headerName: "Discharge Disposition", field: "dischargeDisposition", filter: true },
        { headerName: "Sending Facility", field: "sendingFacilityName", filter: true },
        { headerName: "Source", field: "eventType", filter: 'agTextColumnFilter', hide: true }
      ],
      defaultColDef: {
        autoHeaderHeight: true,
        minWidth: 125,
        resizable: true,
        sortable: true,
        wrapHeaderText: true,
        cellClass: 'ag-grid-block-txt',
        wrapText: true,
        autoHeight: true,

      },
      sideBar: null,
      rowData: null,
      modules: [ClientSideRowModelModule, ClipboardModule, ColumnsToolPanelModule, ExcelExportModule, FiltersToolPanelModule, MenuModule, RowGroupingModule, SetFilterModule]
    };
  },
  created() {
    if (this.$route.query && this.$route.query.corrId) {
      this.getDetails(this.$route.query.corrId);
    }
    this.primeAdtEncounterEnabled = store.getters["appConfig/primeAdtEncounterEnabled"];
  },
  mounted() {
    this.getFullHistory();
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.sideBar = {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressPivotMode: true,
              suppressPivots: true,
              suppressValues: true
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          }
        ]
      };
      this.getFullHistory();
    },
    onRowClicked(params) {
      if (this.isADTsLoading == false) {
        this.getDetails(params.node.data.correlationId);
      }
    },
    jsonFormatter(d) {
      if (d != [] && d != null) {
        let returnString = "";
        let diagCode = d.data.diagnosisCodes

        for (let key in diagCode) {
          if (diagCode[key] != null) {
            if (diagCode[key].code == null || diagCode[key].code == '') {
              returnString += diagCode[key].description + "\n";
            } else if (diagCode[key].description == null || diagCode[key].description == '') {
              returnString += diagCode[key].code + "\n";
            } else if (diagCode[key].code != null && diagCode[key].description != null) {
              returnString += diagCode[key].code + ' | ' + diagCode[key].description + "\n";
            } else {
              returnString += "";
            }
          }
        }
        return returnString;
      } else {
        return d;
      }
    },
    fillLocationRoomPOC(d) {
      let locationString = "";
      let loc = d.data.location;
      let room = d.data.room;
      let poc = d.data.pointOfCare;

      const hasLoc = (loc != "" && loc != null);
      const hasRoom = (room != "" && room != null);
      const hasPoc = (poc != "" && poc != null);

      if (hasLoc) {
        locationString = "Loc: " + loc;
        if (hasRoom || hasPoc) {
          locationString += " | "
        }
      }
      if (hasRoom) {
        locationString += "Room: " + room;
        if (hasPoc) {
          locationString += " | "
        }
      }
      if (hasPoc) {
        locationString += "POC: " + poc;
      }

      return locationString;
    },
    timeFormatter(d) {
      return moment(d.data.eventDate)
        .utc()
        .format("MM/DD/YYYY - h:mmA");
    },
    async getCdsAdtSummary() {
      const method = "get";
      const path = document.config.patientApi + this.currentPatient.payload.beneficiary.id + "/event/full_history";
      this.isADTsLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.isADTsLoading = false;
          if (response.status != 200) {
            throw new Error("Unreachable");
          }
          this.rowData = response.data;
        })
        .catch(error => {
          this.isADTsLoading = false;
          this.$ionic.toastController
            .create({
              header: "Error!",
              message: "There was a problem getting data.",
              duration: 4000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    getCdsAdtDetails(correlationId) {
      const method = "get";
      const path = document.config.patientApi + "event/" + correlationId;
      this.isADTsLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.isADTsLoading = false;
          if (response.status != 200) {
            throw new Error("Unreachable");
          }
          this.openModalNotification(response.data, this.currentPatient.payload.beneficiary.id);
        })
        .catch(error => {
          this.isADTsLoading = false;
          this.$ionic.toastController
            .create({
              header: "Error!",
              message: "There was a problem getting data.",
              duration: 4000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    async getPrimeAdtSummary() {
      this.isADTsLoading = true;
      const primeAdtEncounterSummary = await this.primeAdtEncounterLoad(this.currentPatient.payload.beneficiary.id, 0);
      this.isADTsLoading = false;
      this.rowData = getPrimeEncounter(primeAdtEncounterSummary);
    },
    async gePrimeAdtDetails(correlationId) {
      this.isADTsLoading = true;
      const primeAdtEncounterSummary = await this.primeAdtEncounterLoad(this.currentPatient.payload.beneficiary.id, correlationId);
      this.isADTsLoading = false;
      const primeEncounterDetail = getPrimeEncounter(primeAdtEncounterSummary);
      const detailsObj = primeEncounterDetail.length > 0 ? primeEncounterDetail[0] : {};
      this.openModalNotification(detailsObj, this.currentPatient.payload.beneficiary.id);
    },
    async getFullHistory() {
      // If `primeAdtEncounterEnabled` is true, it calls the Prime ADT summary API.
      // Otherwise, it calls the CDS ADT summary API.      
      if (this.primeAdtEncounterEnabled) {
        await this.getPrimeAdtSummary();
      } else {
        await this.getCdsAdtSummary();
      }
    },
    async getDetails(correlationId) {
      if (this.primeAdtEncounterEnabled) {
        await this.gePrimeAdtDetails(correlationId);
      } else {
        this.getCdsAdtDetails(correlationId);
      }
    },
    openModalNotification(detailsObj, beneId) {
      this.$ionic.modalController
        .create({
          component: ModalEventNotification,
          mode: "ios",
          componentProps: {
            propsData: {
              detailsObj,
              beneId
            }
          },
          backdropDismiss: false
        })
        .then(m => m.present());
    },
    saveState() {
      if (!this.columnApi) return;

      this.saveItemInState("columnState", this.columnApi.getColumnState());
      this.saveItemInState("filterModel", this.gridApi.getFilterModel());
    },
    saveItemInState(key, value) {
      window.localStorage.setItem(`${this.view}.${key}`, JSON.stringify(value));
    },
    getItemFromState(key) {
      return JSON.parse(window.localStorage.getItem(`${this.view}.${key}`));
    },
    async primeAdtEncounterLoad(beneficiary, encounterId) {
      try {
        return await engageFhirApi.get(`/AdtHl7FhirBundle?patientId=${beneficiary}&daysInterval=90&encounterId=${encounterId}`);
      } catch (error) {
        this.isADTsLoading = false;
        if ([error?.status, error?.statusCode].includes(404)) {
          khanSolo.log(`No ADT found for beneficiary ${beneficiary}`);
        } else {
          khanSolo.error(`Error fetching ADT for beneficiary ${beneficiary}`);
          khanSolo.error(error);
        }
        return [];
      }
    },
    async export() { }
  }
};
</script>

<style lang="scss">
@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";

.ag-theme-alpine {
  @include ag-theme-alpine((row-height: 62px,
      font-family: inherit,
      header-background-color: null,
      border-color: rgb(242, 243, 245),
      secondary-border-color: ag-derived(border-color),
      row-border-color: ag-derived(secondary-border-color),
      header-column-resize-handle: true,
      header-column-resize-handle-width: 4px,
      header-column-resize-handle-color: ag-derived(border-color)));

  .ag-root-wrapper {
    border-left: none;
    border-right: none;
    border-bottom: none;
  }

  .ag-root-wrapper,
  .ag-root-wrapper-body {
    height: 100%;
  }
}

.ag-grid-block-txt {
  word-break: normal;
  line-height: 20px !important;
  padding-top: 20px;
  padding-bottom: 20px;
}

.prime-grid {
  width: 100%;
  height: 100%;
}
</style>
